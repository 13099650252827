import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import {Link} from 'react-router-dom';

import dateFormat from 'dateformat';

import { useParams } from 'react-router-dom';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Answer Loader
import AnswerLoader from '../include/AnswerLoader';
// For Answer Loader
// For Button Loader
// import ButtonLoader from '../include/ButtonLoader';
// For Button Loader
// For Question Loader
import QuestionLoader from '../include/QuestionLoader';
// For Question Loader

// For Import QA Loader
import {data} from "../include/Loader";

import "../loader.css";
// For Import QA Loader

import {useNavigate} from "react-router-dom";

// import QALoader from "./QALoader";


const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function Liveexamcontentqa(props) {

    let chooseoption=0;

    let navigate = useNavigate();
    const params=useParams();
    const limit=params.qsl-1;
    const prev=params.qsl-1;
    const next=prev+2;

    let [issavebtn,setIssavebtn]=useState(true);
    let [totalqid, setTotalqid]=useState("");

    let [ismodalalert,setIsmodalalert]=useState("");

    // EXTRA
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
  };

  const formgroup={
      margin: '10px',
      fontWeight: 700
  };
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

    function getcheckexam() {
        if (localStorage.getItem("enrollid")==null) {
            window.location.href="/liveexamresult";
        }
    }

    let [isradio,setIsradio]=useState(false);

    const [datetimeinterval,setDateTimeInterval]=useState("");
    let interval = useRef();

    // function getchecktimer() {

        const startTimer = (endeddate) => {
            const coundownDate = new Date(endeddate).getTime();

            interval=setInterval(()=>{
                const now = new Date().getTime();
                const distance = coundownDate-now;

                // const days=Math.floor(distance / (1000*60*60*24));
                const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
                const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
                const secs=Math.floor((distance % (1000*60)) / 1000);

                if (distance < 0) {
                    //stop timer code
                    clearInterval(interval);
                    setDateTimeInterval("Schedule End");
                    setIsradio(true);
                    setIsskipbtn(true);
                    // autosubmitscore();
                    // setIsbtn(false);

                    // logintokencheck();


                } else {
                    setDateTimeInterval(hours+"h "+mins+"m "+secs+"s");
                    setIsradio(false);
                    // setIsbtn(true);

                    // logintokencheck();

                }
            
            }, 1)
        };
    // }

    let [qid,setQid]=useState("");
    let [question,setQuestion]=useState("");
    let [noted,setNoted]=useState("");
    let [answerarr,setAnswerarr]=useState([]);
    let [answercount,setAnswercount]=useState("");
    let [option,setOption]=useState("");
    let [correctoption,setCorrectoption]=useState("");
    let [ansslno,setAnsslno]=useState("");
    let [anstext,setAnstext]=useState("");

    let [isalertnoted,setIsalertnoted]=useState(false);

    let [studansoption,setStudansoption]=useState("");
    let [querypagecount,setQuerypagecount]=useState(0);
    let [rowpagenoarr,setRowpagenoarr]=useState([]);
    let [querycountcheck,setQuerycountcheck]=useState("");

    let [correctoptiontext,setCorrectoptiontext]=useState("");

    let [isskipbtn,setIsskipbtn]=useState(true);
    let [isnextbtn,setIsnextbtn]=useState(true);

    let [nextbtndisplay,setNextbtndisplay]=useState("block");
    

    let [correct,setCorrect]=useState("");
    let [wrong,setWrong]=useState("");
    let [studansmark,setStudansmark]=useState("");

    // let [anstext,setAnstext]=useState("");

    let [studqstcount,setStudqstcount]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    let [loader,setLoader]=useState(false);

    //year-month [e.g 2022-February] added format in studliveexamscore
    const yearmonth=dateFormat(now, "yyyy-mmmm");
    async function liveexamqa() {

          setLoader(true);
          var fd=new FormData();
          fd.append("livesetid",localStorage.getItem("livesetid"));
          fd.append("limit",limit);
          fd.append("enrollid",localStorage.getItem("enrollid"));
          fd.append("shuffle",localStorage.getItem("shuffle"));
          fd.append("curdate",curdate);

          fd.append("qid",qid);
        //   alert(qid)
          ////////////////ADDED FOR PARTIAL SHUFFLEING
          ////////////////fd.append("qcount",localStorage.getItem('qcount'));
          
          if (localStorage.getItem("shuffle")==0) {
            var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamqa_non_shuffling",fd);
            var data=resp.data;
          } else if (localStorage.getItem("shuffle")==1) {
            var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamqa_shuffling",fd);
            var data=resp.data;
          } else if (localStorage.getItem("shuffle")==2) {
            var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamqa_partial_shuffling",fd);
            var data=resp.data;
          } 
        //   else if (localStorage.getItem("shuffle")==2) {
        //     var resp=await axios.post("https://safalata.in/safalatalara/api/liveexamqa2_sec",fd);
        //     var data=resp.data;
        //   }
          
        //   console.log(data)
          
          setQid(data.qid);
          setQuestion(data.question);
          setNoted(data.noted);
          setAnswerarr(data.ans);
          setOption(data.options);
          setCorrectoption(data.correct_option);
          setCorrectoptiontext(data.correct_option_text);
          setStudansoption(data.stud_ans_option);
          setAnswercount(data.anscount);
          console.log("answerarr==>",data.ans);
          console.log("anscount==>",data.anscount);
        //   console.log("Answers : ",data.ans);
        //   console.log("Answer Count : ",data.anscount);
          
          setRowpagenoarr(data.row_page_no);
          setQuerypagecount(data.query_page_count);
  
          setQuerycountcheck(data.querycountcheck);

          setStudqstcount(data.stud_qst_count);
  
        //   localStorage.setItem('qid',data.qid);
        //   localStorage.setItem('studansoption',data.stud_ans_option);
        //   localStorage.setItem('querycountcheck',data.querycountcheck);
  
        //   if (data.querycountcheck==1) {
        if (data.stud_qst_count==1) {
            setIsskipbtn(true);
            setIsnextbtn(false);
        //   } else if (data.querycountcheck==0) {
        } else if (data.stud_qst_count==0) {
            setIsskipbtn(false);
            setIsnextbtn(true);
          }
  
          setLoader(false);
  
      }


      async function savestudexamsheet() {
        // logintokencheck();
        setIssavebtn(true);
        setIsskipbtn(true);

        if (ansslno==correctoption) {
            chooseoption=1;
        }

        var fd=new FormData();
        fd.append("livesetid",localStorage.getItem("livesetid"));
        fd.append("enrollid",localStorage.getItem("enrollid"));
        fd.append("qid",qid);
        fd.append("ansslno",ansslno);
        fd.append("anstext",anstext);
        // fd.append("querycountcheck",querycountcheck);
        fd.append("studqstcount",studqstcount);
        fd.append("studansoption",studansoption);
        fd.append("pageno",params.qsl);
        fd.append("chooseoption",chooseoption);
        fd.append("correctoption",correctoption);
        fd.append("correctoptiontext",correctoptiontext);
        fd.append("option",option);
        fd.append("answerarr",answerarr);
        
        var resp=await axios.post("https://safalata.in/safalatalara/api/savestudliveexamsheet_qa",fd);
        var data=resp.data;
        
        setIsnextbtn(false);
        setIsalertnoted(true);
        setIsradio(true);
        
        liveexamqa();

        setCorrect(data.correct);
        setWrong(data.wrong);
        setStudansmark(data.studansmark);

    }



    async function skipstudexamsheet() {
    //   logintokencheck();
      // setLoader(true);
      setIsskipbtn(true);
      var fd=new FormData();
      fd.append("livesetid",localStorage.getItem("livesetid"));
      fd.append("enrollid",localStorage.getItem("enrollid"));
      fd.append("qid",qid);
    //   fd.append("querycountcheck",querycountcheck);
      fd.append("studqstcount",studqstcount);
      fd.append("pageno",params.qsl);
      var resp=await axios.post("https://safalata.in/safalatalara/api/skipstudliveexamsheet_qa",fd);
      var data=resp.data;
      
      setIsnextbtn(false);

      liveexamqa();
      // setLoader(false);

    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [oneqst,setOneqst]=useState("");

    // let [circleloader,setCircleloader]=useState(false);

    const [loaderopen, setLoaderopen] = useState(false);
    const loaderhandleOpen = () => setLoaderopen(true);
    const loaderhandleClose = () => setLoaderopen(true);

    const loaderhandlemodalClose = () => setLoaderopen(false);


    function modalhandleOpen() {
        setOneqst("");
        handleOpen();
    }

    async function submitscore() {
        if (querypagecount>0) {
          if (window.confirm("Sure, Submit Online Live Exam Test?")) {
            setIsmodalalert(1);
            setIsOpen(true);
            openModal();

            var fd=new FormData();
            fd.append("livesetid",localStorage.getItem("livesetid"));
            fd.append("enrollid",localStorage.getItem("enrollid"));
            fd.append("examtotalqst",localStorage.getItem("qcount"));
            fd.append("curdate",curdate);
            fd.append("yearmonth",yearmonth);
            var resp=await axios.post("https://safalata.in/safalatalara/api/submitscorestudliveexam_sec",fd);
            var data=resp.data;

            setTimeout(()=>{
                //Remove List of Exam localStorage
                localStorage.removeItem("livesetid");
                localStorage.removeItem("qcount");
                localStorage.removeItem("shuffle");
                localStorage.removeItem('qid');
                localStorage.removeItem('studansoption');
                localStorage.removeItem('querycountcheck');

                // loaderhandlemodalClose();
                setIsOpen(false);
                
                let path = `/liveexamresult`; 
                navigate(path);

            },3000);
          }
        } else {
            setOneqst("Please, Attend One Question Before Submit Score..");
        }

    }

    async function getCheckToken() {

        var fd=new FormData();
          fd.append("enrollid",localStorage.getItem("enrollid"));
          var resp=await axios.post("https://safalata.in/safalatalara/api/checkusertoken",fd);
          var data=resp.data;
          if (data.usertoken!=localStorage.getItem('usertoken')) {
            localStorage.clear();
            let path = `/login`; 
            navigate(path);
          }
    
        //   console.log('db session : '+data.usertoken+' , session : '+localStorage.getItem('usertoken'))
    
    }

    useEffect(()=>{

        getcheckexam();

        getCheckToken();

        // getchecktimer();

        if (params.qsl >= localStorage.getItem('qcount')) {
          setNextbtndisplay("none");
        } else {
          setNextbtndisplay("block");
        }
        
        setIsnextbtn(true);
  
        setTotalqid(localStorage.getItem('qcount'));
  
        // securestayliveexam();
  
        setIsradio(false);

        liveexamqa();
        setIssavebtn(true);
        setIsalertnoted(false);
        setStudansmark("");
        
      },[params.qsl])

    return(
        <>
        
        <div id="content">
            <div className="contentWrapper">
                
                <div className="td5">
                     <div className="col-sm-112">                        
                        <div className="bg_yellow_new border rounded">   

                          <p className="text-defult font-weight-bold text-center" style={{fontSize: 'medium', margin: 0}}>LIVE TEST EXAM</p>   
                          <p className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'large', color: '#0006b5'}}>{localStorage.getItem("examname")}</p>
                          {startTimer(dateFormat(localStorage.getItem("exameddate"), "mmmm dd, yyyy HH:MM:00"))}
                          <p className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'medium', color: '#e61010'}}>( {datetimeinterval} )</p>
                        
                          {/* PAGE NUMBER */}
                        
                          {/* <div className="row"><div className="col-10"> */}

                          {querypagecount>0?<span className="skipicon" style={{fontSize: 'small'}}><i className="fa fa-square"></i>Skip &mdash; </span> :''}
                          {querypagecount>0?    
                          
                            rowpagenoarr.map((r)=>
                            r.stud_ans_option==null?
                            <span style={{fontSize: 'small', fontWeight: '700'}} key={r.studliveexamsheet_pageno}><Link to={"../liveexamqa/"+r.studliveexamsheet_pageno} style={{color: '#ed9a00'}}>{r.studliveexamsheet_pageno}</Link> &nbsp;&nbsp;</span>
                            :''
                            )
                          :''
                          }
                          {querypagecount<=localStorage.getItem('qcount') && querypagecount>0?
                          <span style={{fontSize: 'small', fontWeight: 700}}>&nbsp;&nbsp;<Link to={"../liveexamqa/"+querypagecount} style={{color: '#000794'}}>Last Attend-{querypagecount}</Link> &nbsp;</span>
                          :''}

                          {/* </div></div> */}

                          {/* PAGE NUMBER */}

                          {/* QUESTION */}
                          
                          <hr/> 
                          
                          
                          {/* {!loader?<p className="questiontxt"> */}
                          {!loader?<p className="questiontxt disable-select">
                          
                            Question {params.qsl} of {localStorage.getItem('qcount')} <br/>                     
                              QID - {qid} <br/>
                            Question - {question} 
                          </p>
                          :<QuestionLoader/>}
                          <hr/>

                          {!loader?
                            // {/* ANSWERS */}
                            // answerarr.map((e)=>
                            
                            //     localStorage.getItem('querycountcheck') == 1 && localStorage.getItem('studansoption').length > 0?
                            //         e.answer_slno==localStorage.getItem('studansoption')?

                            //         <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                            //             <input type="radio" name="ans" value={e.answer_slno} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} checked disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.answer.replace(/&apos;/g, "'")}</span>    
                            //         </p>
                            //         : 
                            //         <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                            //             <input type="radio" name="ans" value={e.answer_slno} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.answer.replace(/&apos;/g, "'")}</span>    
                            //         </p>
                            //     : 
                            //     <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                            //     <input type="radio" name="ans" value={e.answer_slno} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} onChange={()=>{
                            //         setAnsslno(e.answer_slno);
                            //         setIssavebtn(false);
                            //     }} disabled={isradio} />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.answer.replace(/&apos;/g, "'")}</span>    
                            //     </p>
                                
                            
                            // )


                            answerarr.map((e,i)=>

                                studqstcount == 1?
                                    studansoption!=""?
                                        e.split("::")[0]==studansoption?
                                        <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                        <input type="radio" name="ans" value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} checked disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</span>
                                        </p>
                                        :
                                        <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                        <input type="radio" name="ans" value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</span>
                                        </p>
                                    : 
                                        <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                        <input type="radio" name="ans" id={"name"+e.split("::")[0]} value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} onChange={()=>{
                                            setAnsslno(e.split("::")[0]);
                                            setAnstext(e.split("::")[1].replace(/&apos;/g, "'"));
                                            setIssavebtn(false);
                                        }} disabled={isradio} />&nbsp;&nbsp;<label for={"name"+e.split("::")[0]} style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</label>    
                                        </p>
                                    // <Radio value={e.split("::")[0]} label={e.split("::")[1].replace(/&apos;/g, "'")} variant="outlined" disabled={isradio} onChange={()=>{
                                    //     setAnsslno(e.split("::")[0]);
                                    //     setAnstext(e.split("::")[1].replace(/&apos;/g, "'"));
                                    //     setIssavebtn(false);
                                    // }} id={"flexRadioDefault"+e.split("::")[0]} />
                                :
                                        <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                        <input type="radio" name="ans" id={"name"+e.split("::")[0]} value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} onChange={()=>{
                                            setAnsslno(e.split("::")[0]);
                                            setAnstext(e.split("::")[1].replace(/&apos;/g, "'"));
                                            setIssavebtn(false);
                                        }} disabled={isradio} />&nbsp;&nbsp;<label for={"name"+e.split("::")[0]} style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</label>    
                                        </p>
                                // <Radio value={e.split("::")[0]} label={e.split("::")[1].replace(/&apos;/g, "'")} variant="outlined" disabled={isradio} onChange={()=>{
                                //         setAnsslno(e.split("::")[0]);
                                //         setAnstext(e.split("::")[1].replace(/&apos;/g, "'"));
                                //         setIssavebtn(false);
                                //     }} id={"flexRadioDefault"+e.split("::")[0]} />
                                    
                            )


                          :
                            <AnswerLoader/>
                          }
                          <br/>
                          {/* ANSWERS */}
                          
                          
                          {studansmark==1?
                          <p className="card-title resdiv">
                            <span className="correcttxt">Correct Answered : {anstext}</span>
                          </p>
                          : studansmark=='-0.25'?
                          <p className="card-title resdiv">
                            <span className="wrongtxt">Wrong Answered : {anstext}</span><br/>
                            <span className="correcttxt">Correct Answer is : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : ''
                          }

                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                            {/* <p className="card-title text-defult font-weight-bold startexamh2txt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}> */}
                            {/* {!loader? */}
                            <div className="text-right">
                              {params.qsl > 1?
                              <Link to={"/liveexamqa/"+prev} className="btn text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}}>Prev</Link>
                              :''}
                              
                              <input type="button" className="btn text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Save" disabled={issavebtn} onClick={savestudexamsheet}/>
                              {/* <input type="button" className="btn text-center displayblockbtn" style={{background: '#4bff33', color: '#000'}} value="Save" disabled={issavebtn} onClick={savestudexamsheet}/> */}
                              {/* <input type="button" className="btn text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Save" disabled={issavebtn} onClick={savestudexamsheet}/> */}
                              

                              {/* {params.qsl < 100? */}
                              <input type="button" className="btn text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Skip" disabled={isskipbtn} onClick={skipstudexamsheet}/>
                              {/* <input type="button" className="btn text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Next" onClick={skipstudexamsheet}/> */}
                              {/* :''
                              } */}


                              {params.qsl < 100?
                                isnextbtn?
                                <input type="button" className="btn text-center displayblockbtn" value="Next" disabled={isnextbtn} style={{background: '#4f38ff', color: '#fff'}}/>
                                :
                                <Link to={"/liveexamqa/"+next} className="btn text-center displayblocknextbtn">Next</Link>
                                // <input type="button" onClick={async ()=>{
                                //   var fd=new FormData();
                                //   fd.append("enrollid", localStorage.getItem("enrollid"));
                                //   fd.append("usertoken", localStorage.getItem("usertoken"));
                                //   var resp=await axios.post("https://safalata.in/safalatalara/api/logintokencheck",fd);
                                //   var data=resp.data;

                                //   if (data.tokencount==0) {
                                //     localStorage.removeItem("tokenID");
                                //     localStorage.removeItem("enrollid");
                                //     localStorage.removeItem("usertoken");
                                //     let path = `/`; 
                                //     navigate(path);
                                //   } else {
                                //     let path = `"/liveexam/"+next`; 
                                //     navigate(path);
                                //   }
                                // }} className="btn text-center displayblockbtn" value="Next" style={{background: '#4f38ff', color: '#fff'}}/>

                              :''
                              }
                            </div>
                              {/* :''
                              } */}
                            {/* : <ButtonLoader/> */}
                            {/* </p> */}
                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                          {/* {studansmark==1?
                          <p className="card-title notedtxt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}>
                            Correct Answered : {correct}
                          </p>
                          : studansmark=='-0.25'?
                          <p className="card-title notedtxt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}>
                            Wrong Answered : {wrong}<br/>
                            Correct Answer is : {correct}
                          </p>
                          : ''
                          } */}

                          {/* {studansmark==1?
                          <p className="card-title resdiv">
                            <span className="correcttxt">Correct Answered : {correct}</span>
                          </p>
                          : studansmark=='-0.25'?
                          <p className="card-title resdiv">
                            <span className="wrongtxt">Wrong Answered : {wrong}</span><br/>
                            <span className="correcttxt">Correct Answer is : {correct}</span>
                          </p>
                          : ''
                          } */}

                          {isalertnoted && noted!=null && noted.length>1?
                          <p className="card-title notedtxt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}>[Note: {noted.replace(/&apos;/g, "'")}]</p>
                          :''}

                          <div className="text-center"><button className="btn btn-danger scorebtn" onClick={submitscore}>LIVE SCORE SUBMIT</button></div>

                        </div>
                    </div>
                </div>
           

            </div>  
        </div>

        {/* For Modal */}
        {ismodalalert==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}

                </div>
            </div>                        

        </Modal>
        : ismodalalert==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="main">
              <div className="form-group text-center" style={formgroup}>
                <Link to="/liveexamresult" className="btn btn-primary">Thank You</Link>
              </div>
            </div>                        

        </Modal>
        :''}
        {/* FOr Modal */}
       
        </>
    );
}

export default Liveexamcontentqa;