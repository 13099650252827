import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import {Link} from 'react-router-dom';

import dateFormat from 'dateformat';

import { useParams } from 'react-router-dom';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Answer Loader
import AnswerLoader from '../include/AnswerLoader';
// For Answer Loader
// For Button Loader
// import ButtonLoader from '../include/ButtonLoader';
// For Button Loader
// For Question Loader
import QuestionLoader from '../include/QuestionLoader';
// For Question Loader

// For Import QA Loader
import {data} from "../include/Loader";

import "../loader.css";
// For Import QA Loader

import {useNavigate} from "react-router-dom";

function Practiceexamcontentqa(props) {

  let chooseoption=0;

  let navigate = useNavigate();

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        //setIsOpen(false);
    }
    // FOr Modal

    const params=useParams();
    
    const limit=params.qsl-1;

    const prev=params.qsl-1;

    const next=prev+2;
    
    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [issavebtn,setIssavebtn]=useState(true);

    if (localStorage.getItem("practicesetid")==null) {
        window.location.href="/practiceexamresult";
    }


    async function securestaypracticeexam() {

      if (localStorage.getItem("enrollid")==null) {
        window.location.href="/practiceexamresult";
      }

    }


    let [isradio,setIsradio]=useState(false);

    // TIME INTERVAL COUNTDOWN

    const [datetimeinterval,setDateTimeInterval]=useState("");

    let interval = useRef();

    const startTimer = (endeddate) => {
        const coundownDate = new Date(endeddate).getTime();

        interval=setInterval(()=>{
            const now = new Date().getTime();
            const distance = coundownDate-now;

            // const days=Math.floor(distance / (1000*60*60*24));
            const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
            const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
            const secs=Math.floor((distance % (1000*60)) / 1000);

            if (distance < 0) {
                //stop timer code
                clearInterval(interval);
                setDateTimeInterval("Schedule End");
                setIsradio(true);
                setIsskipbtn(true);
                // setIsbtn(false);

                // logintokencheck();

            } else {
                setDateTimeInterval(hours+"h "+mins+"m "+secs+"s");
                setIsradio(false);
                // setIsbtn(true);

                // logintokencheck();

            }
        
        }, 1)
    };
    // TIME INTERVAL COUNTDOWN

    let [qid,setQid]=useState("");
    let [question,setQuestion]=useState("");
    let [noted,setNoted]=useState("");
    let [answerarr,setAnswerarr]=useState([]);
    let [ansslno,setAnsslno]=useState("");

    let [isalertnoted,setIsalertnoted]=useState(false);

    let [studansoption,setStudansoption]=useState("");
    let [querypagecount,setQuerypagecount]=useState(0);
    let [rowpagenoarr,setRowpagenoarr]=useState([]);
    let [querycountcheck,setQuerycountcheck]=useState("");

    let [isskipbtn,setIsskipbtn]=useState(true);
    let [isnextbtn,setIsnextbtn]=useState("");

    let [answercount,setAnswercount]=useState("");
    let [option,setOption]=useState("");
    let [correctoption,setCorrectoption]=useState("");
    let [correctoptiontext,setCorrectoptiontext]=useState("");

    let [anstext,setAnstext]=useState("");

    let [studqstcount,setStudqstcount]=useState("");

    

    // let page = 0;
    
    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    //year-month [e.g 2022-February] added format in studpracticeexamscore
    const yearmonth=dateFormat(now, "yyyy-mmmm");

    // alert(curdate);

    async function practiceexamqa() {
        setLoader(true);
        var fd=new FormData();
        fd.append("practicesetid",localStorage.getItem("practicesetid"));
        fd.append("limit",limit);
        fd.append("enrollid",localStorage.getItem("enrollid"));
        fd.append("shuffle",localStorage.getItem("shuffle"));
        fd.append("curdate",curdate);

        fd.append("qid",qid);
        // fd.append("qid",localStorage.getItem("qid"));

        console.log("Limit : ",limit);

        console.log(localStorage.getItem("qid"));


        if (localStorage.getItem("shuffle")==0) {
            var resp=await axios.post("https://safalata.in/safalatalara/api/practiceexamqa_non_shuffling2",fd);
            var data=resp.data;
          } else if (localStorage.getItem("shuffle")==1) {
            var resp=await axios.post("https://safalata.in/safalatalara/api/practiceexamqa_shuffling2",fd);
            var data=resp.data;
          } 
        
        
        setQid(data.qid);
        // const qid=data.qid;
        setQuestion(data.question);
        setNoted(data.noted);
        setAnswerarr(data.ans);
        setOption(data.options);
        setCorrectoption(data.correct_option);
        setCorrectoptiontext(data.correct_option_text);
        setStudansoption(data.stud_ans_option);
        setAnswercount(data.anscount);
        
        setRowpagenoarr(data.row_page_no);
        setQuerypagecount(data.query_page_count);

        setQuerycountcheck(data.querycountcheck);

        setStudqstcount(data.stud_qst_count);

        localStorage.setItem('qid',data.qid);
        localStorage.setItem('studansoption',data.stud_ans_option);
        localStorage.setItem('querycountcheck',data.querycountcheck);

        // if (data.querycountcheck==1) {
        if (data.stud_qst_count==1) {
          setIsskipbtn(true);
          setIsnextbtn(false);
        // } else if (data.querycountcheck==0) {
        } else if (data.stud_qst_count==0) {
          setIsskipbtn(false);
          setIsnextbtn(true);
        }

        console.log("querycountcheck : ",data.querycountcheck);
        console.log("studansoption : ",data.stud_ans_option);

        console.log("stud_qst_count : ",data.stud_qst_count);

        setLoader(false);

    }

    let [correct,setCorrect]=useState("");
    let [wrong,setWrong]=useState("");
    let [studansmark,setStudansmark]=useState("");

    async function savestudpracticeexamsheet() {
        logintokencheck();
        setIssavebtn(true);
        setIsskipbtn(true);

        if (ansslno==correctoption) {
            chooseoption=1;
        }
        console.log(chooseoption);

        var fd=new FormData();
        fd.append("practicesetid",localStorage.getItem("practicesetid"));
        fd.append("enrollid",localStorage.getItem("enrollid"));
        fd.append("qid",qid);
        fd.append("ansslno",ansslno);
        fd.append("anstext",anstext);
        // fd.append("querycountcheck",querycountcheck);
        fd.append("studqstcount",studqstcount);
        fd.append("studansoption",studansoption);
        fd.append("pageno",params.qsl);
        fd.append("chooseoption",chooseoption);
        fd.append("correctoption",correctoption);
        fd.append("correctoptiontext",correctoptiontext);
        fd.append("option",option);
        fd.append("answerarr",answerarr);
        var resp=await axios.post("https://safalata.in/safalatalara/api/savestudpracticeexamsheet_qa2",fd);
        var data=resp.data;
        
        setIsnextbtn(false);
        setIsalertnoted(true);
        setIsradio(true);

        console.log(isnextbtn)

        console.log(answerarr)
        
        practiceexamqa();

        setCorrect(data.correct);
        setWrong(data.wrong);
        setStudansmark(data.studansmark);

    }

    async function skipstudpracticeexamsheet() {
      logintokencheck();
      // setLoader(true);
      setIsskipbtn(true);
      var fd=new FormData();
      fd.append("practicesetid",localStorage.getItem("practicesetid"));
      fd.append("enrollid",localStorage.getItem("enrollid"));
      fd.append("qid",qid);
      // fd.append("querycountcheck",querycountcheck);
      fd.append("studqstcount",studqstcount);
      fd.append("pageno",params.qsl);
      var resp=await axios.post("https://safalata.in/safalatalara/api/skipstudpracticeexamsheet_qa2",fd);
      var data=resp.data;
      
      setIsnextbtn(false);

      practiceexamqa();
      // setLoader(false);

    }


    async function submitpracticescore() {
        if (querypagecount>0) {
          if (window.confirm("Sure, Submit Online Practice Exam Test?")) {
            setIsOpen(true);
            openModal();

            var fd=new FormData();
            fd.append("practicesetid",localStorage.getItem("practicesetid"));
            fd.append("enrollid",localStorage.getItem("enrollid"));
            fd.append("examtotalqst",localStorage.getItem("qcount"));
            fd.append("curdate",curdate);
            fd.append("yearmonth",yearmonth);
            var resp=await axios.post("https://safalata.in/safalatalara/api/submitscorestudpracticeexam_sec",fd);
            var data=resp.data;

            setTimeout(()=>{
              //Remove List of Exam localStorage
              localStorage.removeItem("practicesetid");
              localStorage.removeItem("qcount");
              localStorage.removeItem("shuffle");
              localStorage.removeItem('qid');
              localStorage.removeItem('studansoption');
              localStorage.removeItem('querycountcheck');

              setIsOpen(false);
            //   window.location.href="/practiceexamresult";
                let path = `/practiceexamresult`; 
                navigate(path);
            },3000);
            
          }
        } else {
          alert("Please, Attend One Question Before Submit Score..")
        }
    }

    async function logintokencheck() {
      if (!localStorage.getItem("usertoken")) {
          localStorage.removeItem("tokenID");
          localStorage.removeItem("enrollid");
  
          let path = `/`; 
          navigate(path);
      }
        var fd=new FormData();
        fd.append("enrollid", localStorage.getItem("enrollid"));
        fd.append("usertoken", localStorage.getItem("usertoken"));
        var resp=await axios.post("https://safalata.in/safalatalara/api/logintokencheck",fd);
        var data=resp.data;
  
        if (data.tokencount==0) {
          localStorage.removeItem("tokenID");
          localStorage.removeItem("enrollid");
          localStorage.removeItem("usertoken");
          let path = `/`; 
          navigate(path);
          // window.location.href="/";
        }
        console.log(localStorage.getItem("usertoken"));
  
    }


    async function getCheckToken() {

      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      var resp=await axios.post("https://safalata.in/safalatalara/api/checkusertoken",fd);
      var data=resp.data;
      if (data.usertoken!=localStorage.getItem('usertoken')) {
        localStorage.clear();
        let path = `/`; 
        navigate(path);
      }

    }


    useEffect(()=>{

      // setIsnextbtn(true);

      getCheckToken();

      setIsnextbtn(true);

      securestaypracticeexam();

      setIsradio(false);

      // getToken();
      // practiceexamdetails();
      practiceexamqa();
      setIssavebtn(true);
      setIsalertnoted(false);
      setStudansmark("");
      // pagenosecure();

      // logintokencheck();
      
    },[params.qsl])

    return(
        <>
        
        <div id="content">
            <div className="contentWrapper">
                

                <div className="td5">
                     <div className="col-sm-112">
                        
                        <div className="bg_yellow_new border rounded">   

                          <p className="text-defult font-weight-bold text-center" style={{fontSize: 'medium', margin: 0}}>PRACTICE TEST EXAM</p>   
                          {/* <p className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'x-large', color: '#e61010'}}>{examname}</p>
                          {startTimer(dateFormat(exameddate, "mmmm dd, yyyy HH:MM:00"))} */}
                          <p className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'large', color: '#0006b5'}}>{localStorage.getItem("examname")}</p>
                          {startTimer(dateFormat(localStorage.getItem("exameddate"), "mmmm dd, yyyy HH:MM:00"))}                          
                          <p className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'medium', color: '#e61010'}}>( {datetimeinterval} )</p>
                          
                          {/* PAGE NUMBER */}
                          
                          {/* <div className="row">
                              <div className="col-lg-12 text-right">
                                  <span className="ansidentity">Answer Identity</span>
                                  <span className="skipicon"><i className="fa fa-square"></i> &mdash; Skip</span>
                                  <span className="newicon"><i className="fa fa-square"></i> &mdash; New</span>
                              </div>
                          </div> */}
                          
                          

                          {querypagecount>0?<span className="skipicon" style={{fontSize: 'small'}}><i className="fa fa-square"></i>Skip &mdash; </span> :''}
                          {querypagecount>0?    
                                              
                            rowpagenoarr.map((r)=>
                            r.stud_ans_option==null?
                            <span style={{fontSize: 'small', fontWeight: '700'}} key={r.studpracticeexamsheet_pageno}>&nbsp;&nbsp;<Link to={"../practiceexamqa/"+r.studpracticeexamsheet_pageno} style={{color: '#ed9a00'}}>{r.studpracticeexamsheet_pageno}</Link>&nbsp;&nbsp;</span>
                            :''
                            )
                          :''
                          }
                          {querypagecount<=localStorage.getItem('qcount') && querypagecount>0?
                          <span style={{fontSize: 'small', fontWeight: 700}}>&nbsp;&nbsp;<Link to={"../practiceexamqa/"+querypagecount} style={{color: '#000794'}}>Last Attend-{querypagecount}</Link>&nbsp;&nbsp;</span>
                          :''}

                          <span role="button" onClick={()=>{
                            window.location.reload();
                            // practiceexamqa();
                          }}>
                          <i className="fas fa-sync"></i>
                          </span>
                          

                          {/* PAGE NUMBER */}

                          {/* QUESTION */}
                          
                          <hr/> 
                          
                          {!loader?<p className="questiontxt disable-select">
                            Question {params.qsl} of {localStorage.getItem('qcount')} <br/>                     
                              QID - {qid} <br/>
                            Question - {question} 
                          </p>
                          :<QuestionLoader/>}
                          <hr/>

                          {!loader?
                          // {/* ANSWERS */}
                          answerarr.map((e)=>
                          
                            // localStorage.getItem('querycountcheck') == 1 && localStorage.getItem('studansoption').length > 0?
                            studqstcount == 1?
                                studansoption!=""?
                                    e.split("::")[0]==studansoption?
                                      <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                          <input type="radio" name="ans" value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} checked disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</span>    
                                      </p>
                                    : 
                                      <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                          <input type="radio" name="ans" value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</span>    
                                      </p>
                                :
                                    <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                        <input type="radio" name="ans" value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} onChange={()=>{
                                          // setAnsslno(e.answer_slno);
                                          setAnsslno(e.split("::")[0]);
                                          setAnstext(e.split("::")[1].replace(/&apos;/g, "'"));
                                          setIssavebtn(false);
                                        }} id={"flexRadioDefault"+e.split("::")[0]} />&nbsp;&nbsp;<label class="form-check-label" for={"flexRadioDefault"+e.split("::")[0]}><span style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</span></label>  
                                    </p>
                            : 
                            <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                              <input type="radio" name="ans" value={e.split("::")[0]} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} onChange={()=>{
                                // setAnsslno(e.answer_slno);
                                setAnsslno(e.split("::")[0]);
                                setAnstext(e.split("::")[1].replace(/&apos;/g, "'"));
                                setIssavebtn(false);
                              }} disabled={isradio} id={"flexRadioDefault"+e.split("::")[0]} />&nbsp;&nbsp;<label class="form-check-label" for={"flexRadioDefault"+e.split("::")[0]}><span style={{color: '#000'}}>{e.split("::")[1].replace(/&apos;/g, "'")}</span></label>
                            </p>
                          
                          
                          )
                          :<AnswerLoader/>}<br/>
                          {/* ANSWERS */}
                          
                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                          {studansmark==1?
                          <p className="card-title resdiv">
                            <span className="correcttxt">Correct Answered : {anstext}</span>
                          </p>
                          : studansmark=='-0.25'?
                          <p className="card-title resdiv">
                            <span className="wrongtxt">Wrong Answered : {anstext}</span><br/>
                            <span className="correcttxt">Correct Answer is : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : ''
                          }
                          
                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                            {/* <p className="card-title text-defult font-weight-bold startexamh2txt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}> */}
                            {/* {!loader? */}
                            <div className="text-right">
                              {params.qsl > 1?
                              <Link to={"/practiceexamqa/"+prev} className="btn btn-info text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}}>Prev</Link>
                              :''}
                              
                              
                              <input type="button" className="btn btn-success text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Save" disabled={issavebtn} onClick={savestudpracticeexamsheet}/>

                              <input type="button" className="btn btn-danger text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Skip" disabled={isskipbtn} onClick={skipstudpracticeexamsheet}/>

                              {/* {params.qsl < examtotalqst? */}
                              {/* {params.qsl < localStorage.getItem("qcount")? */}
                              {params.qsl < 100?
                                isnextbtn==true?
                                <input type="button" className="btn btn-info text-center displayblockbtn" value="Next" disabled={isnextbtn} style={{background: '#4f38ff', color: '#fff'}} />
                                :
                                <Link to={"/practiceexamqa/"+next} className="btn btn-info text-center displayblocknextbtn">Next</Link>
                              :''
                              }
                            </div>
                            {/* : <ButtonLoader/> */}
                            {/* </p> */}
                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                          {/* {studansmark==1?
                          <p className="card-title resdiv">
                            <span className="correcttxt">Correct Answered : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : studansmark=='-0.25'?
                          <p className="card-title resdiv">
                            <span className="wrongtxt">Wrong Answered : {wrong.replace(/&apos;/g, "'")}</span><br/>
                            <span className="correcttxt">Correct Answer is : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : ''
                          } */}

                          {isalertnoted && noted!=null?
                          
                          <p className="card-title notedtxt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}><hr/>[ Note: {noted.replace(/&apos;/g, "'")} ]</p>
                          :''}

                          <div className="text-center"><button className="btn btn-danger scorebtn" onClick={submitpracticescore}>PRACTICE SCORE SUBMIT</button></div>

                        </div>
                    </div>
                </div>
           

            </div>  
        </div>

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}

                </div>
            </div>                        

        </Modal>
        {/* FOr Modal */}
       
        </>
    );
}

export default Practiceexamcontentqa;